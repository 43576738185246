// Import dependencies
import React from "react";
import { useState } from "react"
import {useStaticQuery, graphql, Link, navigate} from "gatsby";
import { Router } from "@reach/router";
import Img from "gatsby-image";

// Import components
import Layout from "../layouts/layout";
import SEO from "../components/seo/seo";
import HeaderSeparator from "../components/separators/headerSeparator";
import SectionSeparator from "../components/separators/sectionSeparator";
import CategoryDrawer from "../components/cateogry-drawer/categoty-drawer";
import SearchBar from "../components/search-bar/searchBar";
import BrandContent from "../components/department/brandContent";
import BrdNotFound from "../components/department/brdNotFound";
import BrandPopup from "../components/department/brandPopup";
import {connect} from "react-redux";
import {brandTiles, brandData} from "../components/department/departmentData";
import Loader from "../components/loader/loader";
import BrandNotFound from "../components/department/brandNotFound";

import { brandCountLoaded } from "../redux/actions/brandCountAction"
import {getFirebase} from "../api/firebase";

// Import styless
import "./index.css";


/*
    Index page component
*/
class Brand extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        popupOpen: false,
        brands: "",
        brandAvailable: false,
        notBrand: false,
        scrollPosition: 0,
        windowWith: 2000,
        brandCountLoaded: false,
        images: []
      };
      this.togglePopup = this.togglePopup.bind(this);
      this.setBrand = this.setBrand.bind(this);
      this.listenToScroll = this.listenToScroll.bind(this);
      this.listenToResize = this.listenToResize.bind(this);
      this.isBrowser = this.isBrowser.bind(this);
    }
    togglePopup = () => {
      this.setState({
        popupOpen: !this.state.popupOpen
      });
    }
    setBrand = (getBrand) => {
      let brandTitle = Object.values(this.props.store.productNameDetails)
      .filter((product) => {
        return product.Product.toLowerCase().replace("other ", "").startsWith(getBrand[0].toLowerCase())
      })
      .map((product) => {
        if ((product.Department == "Books" || product.Department == "Cookware" || product.Department == "Clothes") && !(product.Product.startsWith("OTHER"))) {
          return product.Product.replace("OTHER ", "").replace(" " + product.SubCategory.toUpperCase(), "")
        } else if (product.Department == "Electronics" && product.Product.startsWith("OTHER") && product.Product !=  "OTHER " + product.SubCategory.toUpperCase()) {
          return product.Product.replace("OTHER ", "").replace(" " + product.SubCategory.toUpperCase(), "")
        } else {
          return false
        }
      })
      .filter((value, index, self) => {
        return self.indexOf(value) === index && value != "" ;
      })
      .filter((brand) => brand.toLowerCase().replace(/[^\w\s]/gi, '').replace(/\s/g, '') == getBrand)[0]
      ;

      console.log(brandTitle);


      // All product with the brand name
      let productUnderBrand = Object.values(this.props.store.productNameDetails)
      .filter((productName) => {
        return productName.Product.replace("\"", "").startsWith(brandTitle + " ") || productName.Product.replace("\"", "").startsWith("OTHER " + brandTitle + " ")
      });
      console.log(Object.values(this.props.store.productNameDetails));
      if (getBrand == "marshall") {
        productUnderBrand = productUnderBrand.length != 0 ? productUnderBrand.filter((product) => product.Department == "Electronics") : productUnderBrand;
      }
      if (getBrand == "not-found") {
        this.setState({notBrand: true,
          brandAvailable: true})
      } else if (!(brandTitle) && getBrand != "not-found") {
        navigate("/404");
      }  else if (getBrand != "not-found") {
        let shortLength = 0;
        let brandDepartment = productUnderBrand[0].Department;

        // Subcategory object initial
        let brandCategories = [];
        productUnderBrand.forEach((product) => {
          brandCategories.push({
            "name": product.SubCategory.toLowerCase().replace(/[^\w\s]/gi, '').replace(/\s/g, ''),
            "title": product.SubCategory,
            "items": []
          })
        });


        // Removing duplicate subCategory
        brandCategories = brandCategories.filter((thing, index, self) =>
          index === self.findIndex((t) => (
            t.place === thing.place && t.name === thing.name
          ))
        );

        // Adding items
        console.log("productUnderBrand");
        console.log(productUnderBrand);

        productUnderBrand.forEach((product) => {
         brandCategories.forEach((category, indexCA) => {
           if (product.SubCategory == category.title && !(product.Product.startsWith("OTHER"))) {
            let productImage = this.props.store.images && this.props.store.images.filter(image => image.product == product.Product);
            brandCategories[indexCA].items.push({
              "refInd" : product.ProductID,
              "title" : product.Product.replace(brandTitle + " ", ""),
              "price" : product.Member["No problems"]["Looks new"],
              "image" : productImage?.length ? productImage[0].image : null
            }) 
           }
         });
        });
        console.log("brandCategories");
        console.log(brandCategories);
        if (brandData[0][getBrand].tiles) {
          // move first subcategory tile to first
          let indexTile = brandCategories.map((product) => {
            return product.name;
          }).indexOf(brandData[0][getBrand].tiles[0]);
          let cutOut = brandCategories.splice(indexTile, 1) [0];
          brandCategories.splice(0, 0, cutOut);
          // move second subcategory tile to second if available
          if (brandData[0][getBrand].tiles.length > 1) {
            indexTile = brandCategories.map((product) => {
              return product.name;
            }).indexOf(brandData[0][getBrand].tiles[1]);
            cutOut = brandCategories.splice(indexTile, 1) [0];
            brandCategories.splice(1, 0, cutOut);
          }
        }

        this.setState({offlineRecycle: brandData[0][getBrand].offlineRecycle});

                      // Brand main object
        let brand = {"title" : brandTitle,
                     "name" : getBrand,
                     "department" : brandDepartment,
                     "categories": brandCategories
                   };
        this.setState({
          brands: brand,
          brandAvailable: true,
        });

      }
    }

    componentWillMount() {

      if (this.props.brandCount) {
        const app = import("firebase/app");
        const firestore = import("firebase/firestore");

        
        Promise.all([app, firestore])
        .then(([firebase]) =>
            getFirebase(firebase)
                .firestore()
                .collection("images")
                .get()
                .then((querySnapshot) => {
                  let images = [];
                  querySnapshot.forEach((doc) => {
                    images.push(doc.data());
                  });

                  getFirebase(firebase)
                  .firestore()
                  .collection("brands")
                  .doc("count")
                  .get()
                  .then(doc => {
                      this.props.dispatch(brandCountLoaded({images: images, brandCount: doc.data()}));
                      this.setState({brandCountLoaded: true});
                  })
              })
        );  
      }
      
    }

    componentDidMount() {
      window.addEventListener('scroll', this.listenToScroll);
      window.addEventListener("resize", this.listenToResize);
      if (this.isBrowser()) {
        this.setState({
          windowWith: window.innerWidth
        });
      }
      if (!this.state.brandCountLoaded) {
        const app = import("firebase/app");
        const firestore = import("firebase/firestore");
        Promise.all([app, firestore])
        .then(([firebase]) =>

          getFirebase(firebase)
          .firestore()
          .collection("images")
          .get()
          .then((querySnapshot) => {
            let images = [];
            querySnapshot.forEach((doc) => {
              images.push(doc.data());
            });

            getFirebase(firebase)
            .firestore()
            .collection("brands")
            .doc("count")
            .get()
            .then(doc => {
                this.props.dispatch(brandCountLoaded({images: images, brandCount: doc.data()}));
                this.setState({brandCountLoaded: true}, function() {
                  this.setBrand(this.props.params['*'].split("/")[0]);
                });
            })
        })
        );  
      }
    }
    
    componentWillUnmount() {
      window.removeEventListener('scroll', this.listenToScroll);
      window.removeEventListener("resize", this.listenToResize);
    }

    isBrowser = () => typeof window !== "undefined";
    
    listenToScroll = () => {
      if (this.isBrowser()) {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        this.setState({
          scrollPosition: winScroll
        })  
      }
    }
    
    listenToResize = () => {
      if (this.isBrowser()) {
        const winWidth = window.innerWidth;
        this.setState({
          windowWith: winWidth
        })  
      }
    }

    render() {
        if (this.state.brandAvailable) {
      return (
        <Layout noWhatsapp>

            <CategoryDrawer setBrand={(brand) => this.setBrand(brand)} brandPage/>

            <SEO
                title="Home"
                description="On-demand buy-back and recycling for used electronics, books, and clothes."
            />
            <BrandPopup popupOpen={this.state.popupOpen} togglePopup={this.togglePopup}/>
            <SearchBar HomeSearchPadding={true} brandPage setBrand={(brand) => this.setBrand(brand)}/>

            { this.state.notBrand
              ?
              <BrandNotFound 
                departmenTitle={"Brand not shown"}
                categories={
                  [].concat.apply([],
                    Object.values(this.props.store.categoryTree).map(category => Object.keys(category) )
                    )}
              />
              :
              <Router>
              <BrandContent path="/brand/:title"
                brandTitle={this.state.brands.title}
                brandDepartment={this.state.brands.department}
                categories={this.state.brands.categories}
                togglePopup={this.togglePopup}
                fixedHeader={
                  this.state.scrollPosition >= 70 && this.state.windowWith > 850
                    ||
                  this.state.scrollPosition >= 123 && this.state.windowWith <= 850
                }
                offlineRecycle={this.state.offlineRecycle}
                brandCount={this.props.store.brandCount}
                brandCountLoaded={this.props.store.images && this.props.store.images?.length ? true : false}
                other={false}
              />
              <BrandContent path="/brand/:title/:category"
                brandTitle={this.state.brands.title}
                brandDepartment={this.state.brands.department}
                categories={this.state.brands.categories}
                togglePopup={this.togglePopup}
                fixedHeader={
                  this.state.scrollPosition >= 70 && this.state.windowWith > 850
                    ||
                  this.state.scrollPosition >= 123 && this.state.windowWith <= 850
                }
                offlineRecycle={this.state.offlineRecycle}
                brandCount={this.props.store.brandCount}
                brandCountLoaded={this.props.store.images && this.props.store.images?.length ? true : false}
                other={false}
              />
              <BrandContent path="/brand/:title/:category/other"
                brandTitle={this.state.brands.title}
                brandDepartment={this.state.brands.department}
                categories={this.state.brands.categories}
                togglePopup={this.togglePopup}
                fixedHeader={
                  this.state.scrollPosition >= 70 && this.state.windowWith > 850
                    ||
                  this.state.scrollPosition >= 123 && this.state.windowWith <= 850
                }
                offlineRecycle={this.state.offlineRecycle}
                brandCount={this.props.store.brandCount}
                brandCountLoaded={this.props.store.images && this.props.store.images?.length ? true : false}
                other={true}
              />
              </Router>
              
            }

        </Layout>
    );
  } else {
    return(<></>)
  }
  }
}


function readProductsList(storeState) {
    let store = {
        productName: storeState.product.productNamesById,
        categoryTree: storeState.product.productCategoryTree,
        productNameDetails: storeState.product.productsIndexedById,
        brandCount: storeState.brandAndImage.brandCount,
        images: storeState.brandAndImage.images,
      };
    return {store};
}

export default connect(readProductsList)(Brand);
export const brandTiles = [
  {
    "acer": [
      "desktops",
      "laptops"
    ],
    "aerogaz": [
      "kettles",
      "showerheaters"
    ],
    "amazon": [
      "ereaders",
      "smarthomedevices"
    ],
    "aox": [
      "waterdispensers"
    ],
    "apple": [
      "cellphones",
      "laptops"
    ],
    "asus": [
      "gaminglaptops",
      "laptops"
    ],
    "babyliss": [
      "haircurlers",
      "hairirons"
    ],
    "bangolufsen": [
      "headphones",
      "speakers"
    ],
    "elkin": [
      "powerbanks",
      "routers"
    ],
    "benq": [
      "monitors",
      "projectors"
    ],
    "bernina": [
      "sewingmachines"
    ],
    "bitmain": [
      "asicminers"
    ],
    "blackberry": [
      "cellphones"
    ],
    "blackdecker": [
      "handpowertools",
      "vacuumcleaners"
    ],
    "lendtec": [
      "blenders"
    ],
    "blueair": [
      "airtreaters"
    ],
    "bluewaterspirit": [
      "waterpurifiers"
    ],
    "bosch": [
      "juicers",
      "kettles"
    ],
    "bose": [
      "headphones",
      "speakers"
    ],
    "brandt": [
      "winecoolers"
    ],
    "braun": [
      "haircurlers",
      "garmentirons"
    ],
    "breville": [
      "coffeemachines",
      "juicers"
    ],
    "brother": [
      "printers",
      "scanners"
    ],
    "ado": [
      "airtreaters"
    ],
    "canaan": [
      "asicminers"
    ],
    "canon": [
      "camerabodies",
      "printers"
    ],
    "casio": [
      "calculators",
      "projectors"
    ],
    "chateau": [
      "winecoolers"
    ],
    "cisco": [
      "routers"
    ],
    "clickandgrow": [
      "indoorgardeningkits",
    ],
    "clothing": [
      "jeans",
      "shirts",
      "sportswear"
    ],
    "cornell": [
      "kettles",
      "slowcookers"
    ],
    "coway": [
      "airtreaters"
    ],
    "creative": [
      "headphones",
      "speakers"
    ],
    "crockpot": [
      "slowcookers"
    ],
    "cuchen": [
      "breadmakers",
      "ricecookers"
    ],
    "cuisinart": [
      "blenders",
      "icecreammachines"
    ],
    "dafni": [
      "ceramichairbrushes"
    ],
    "dell": [
      "desktops",
      "laptops"
    ],
    "delonghi": [
      "coffeegrinders",
      "coffeemachines"
    ],
    "devialet": [
      "headphones",
      "speakers"
    ],
    "dieboldnixdorf": [
      "receiptprinters"
    ],
    "dji": [
      "drones",
      "stabilisers"
    ],
    "dlink": [
      "routers",
      "smarthomedevices"
    ],
    "link": [
      "smarthomedevices"
    ],
    "dyson": [
      "floorfans",
      "vacuumcleaners"
    ],
    "ecovacs": [
      "vacuumcleaners",
      "windowcleaners"
    ],
    "educationalpublishinghouse": [
      "textbooks"
    ],
    "electrolux": [
      "vacuumcleaners",
      "airtreaters"
    ],
    "epson": [
      "printers",
      "projectors"
    ],
    "europace": [
      "airtreaters",
      "winecoolers"
    ],
    "excalibur": [
      "fooddehydrators"
    ],
    "fender": [
      "amplifiers",
      "speakers"
    ],
    "fitbit": [
      "smartwatches"
    ],
    "fujifilm": [
      "camerabodies",
      "cameralenses"
    ],
    "gaggia": [
      "coffeegrinders",
      "coffeemachines"
    ],
    "gamevice": [
      "gamingperipherals"
    ],
    "garmin": [
      "smartwatches"
    ],
    "gigaset": [
      "telephones"
    ],
    "google": [
      "cellphones",
      "smarthomedevices"
    ],
    "gopro": [
      "camerabodies"
    ],
    "greenlife": [
      "fryingpans",
      "saucepots"
    ],
    "hachette": [
      "fictionbooks",
      "non-fictionbooks"
    ],
    "haier": [
      "airtreaters",
      "vacuumcleaners"
    ],
    "hamiltonbeach": [
      "blenders",
      "garmentirons"
    ],
    "happycall": [
      "fryingpans",
      "saucepots"
    ],
    "harmankardon": [
      "smarthomedevices",
      "speakers"
    ],
    "harpercollins": [
      "fictionbooks",
      "non-fictionbooks"
    ],
    "hisense": [
      "cellphones",
      "flatscreentelevisions"
    ],
    "hitachi": [
      "ricecookers",
      "vacuumcleaners"
    ],
    "hoddereducation": [
      "textbooks"
    ],
    "honeywell": [
      "airtreaters",
      "floorfans"
    ],
    "hp": [
      "laptops",
      "printers"
    ],
    "htcvive": [
      "arvrheadsets"
    ],
    "huawei": [
      "cellphones",
      "smartwatches"
    ],
    "hurom": [
      "blenders",
      "juicers"
    ],
    "ikea": [
      "fryingpans",
      "saucepots"
    ],
    "illy": [
      "coffeemachines"
    ],
    "instantpot": [
      "slowcookers"
    ],
    "irobot": [
      "vacuumcleaners"
    ],
    "jabra": [
      "conferencespeakers",
      "headphones"
    ],
    "jbl": [
      "headphones",
      "speakers"
    ],
    "jura": [
      "coffeemachines"
    ],
    "jvc": [
      "carvideorecorders",
      "flatscreentelevisions"
    ],
    "kadeka": [
      "winecoolers"
    ],
    "karcher": [
      "vacuumcleaners"
    ],
    "kdk": [
      "floorfans"
    ],
    "kenwood": [
      "kettles",
      "standmixers"
    ],
    "kitchenaid": [
      "foodprocessors",
      "standmixers"
    ],
    "klipsch": [
      "headphones",
      "speakers"
    ],
    "krups": [
      "coffeemachines",
      "toasters"
    ],
    "kuvings": [
      "blenders",
      "juicers"
    ],
    "lacor": [
      "sousvidesticks"
    ],
    "lagourmet": [
      "pressurecookers",
      "ricecookers"
    ],
    "laica": [
      "bodycompositionmonitors",
      "thermometers"
    ],
    "lamarzocco": [
      "coffeegrinders",
      "coffeemachines"
    ],
    "leica": [
      "camerabodies",
      "cameralenses"
    ],
    "lelit": [
      "coffeegrinders",
      "coffeemachines"
    ],
    "lenovo": [
      "laptops",
      "tablets"
    ],
    "lg": [
      "cellphones",
      "laptops"
    ],
    "lifesense": [
      "digitalscales",
      "smartwatches"
    ],
    "linksys": [
      "routers"
    ],
    "logitech": [
      "arvrheadsets",
      "webcameras"
    ],
    "lotusgrill": [
      "bbqgrills"
    ],
    "macmillan": [
      "classicnovels",
      "referencebooks"
    ],
    "magimix": [
      "blenders",
      "foodprocessors"
    ],
    "marshallcavendish": [
      "childrensbooks",
      "textbooks"
    ],
    "marshall": [
      "amplifiers",
      "speakers"
    ],
    "mayer": [
      "ricecookers",
      "winecoolers"
    ],
    "microsoft": [
      "gamingconsoles",
      "laptops"
    ],
    "midea": [
      "toasters",
      "vacuumcleaners"
    ],
    "miele": [
      "coffeemachines",
      "vacuumcleaners"
    ],
    "istral": [
      "floorfans"
    ],
    "mistral": [
      "ricecookers"
    ],
    "morphy richards": [
      "kettles",
      "toasters"
    ],
    "motorola": [
      "cellphones",
      "telephones"
    ],
    "msi": [
      "gaminglaptops"
    ],
    "nakamichi": [
      "digitalclocks",
      "speakers"
    ],
    "nec": [
      "laptops",
      "projectors"
    ],
    "espresso": [
      "coffeemachines"
    ],
    "etgear": [
      "routers"
    ],
    "nikon": [
      "camerabodies",
      "cameralenses"
    ],
    "nintendo": [
      "gamingconsoles"
    ],
    "nokia": [
      "cellphones",
      "routers"
    ],
    "novita": [
      "airtreaters",
      "waterpurifiers"
    ],
    "oaxis": [
      "smartwatches",
      "thermometers"
    ],
    "oculus": [
      "arvrheadsets"
    ],
    "olympus": [
      "camerabodies",
      "cameralenses"
    ],
    "omron": [
      "bloodpressuremonitors",
      "bodycompositionmonitors"
    ],
    "oneplus": [
      "cellphones",
      "headphones"
    ],
    "oppo": [
      "cellphones",
      "headphones"
    ],
    "oster": [
      "blenders",
      "toasters"
    ],
    "panasonic": [
      "camerabodies",
      "ricecookers"
    ],
    "parrot": [
      "drones"
    ],
    "pearson": [
      "referencebooks",
      "textbooks"
    ],
    "penguinrandomhouse": [
      "classicnovels",
      "fictionbooks"
    ],
    "phaidon": [
      "artbooks",
      "childrensbooks"
    ],
    "philips": [
      "ricecookers",
      "vacuumcleaners"
    ],
    "pioneer": [
      "headphones",
      "hometheatreavsystems"
    ],
    "razer": [
      "gaminglaptops",
      "gamingperipherals"
    ],
    "realme": [
      "cellphones",
      "smartwatches"
    ],
    "remington": [
      "electricshavers",
      "hairdryers"
    ],
    "ricoh": [
      "camerabodies",
      "projectors"
    ],
    "roku": [
      "digitalmediaplayers"
    ],
    "rommelsbacher": [
      "coffeegrinders",
      "toasterovens"
    ],
    "rowenta": [
      "toasterovens",
      "vacuumcleaners"
    ],
    "samsung": [
      "cellphones",
      "smartwatches"
    ],
    "scanpan": [
      "fryingpans",
      "saucepots"
    ],
    "sennheiser": [
      "headphones",
      "microphones"
    ],
    "severin": [
      "kettles",
      "toasterovens"
    ],
    "harkninja": [
      "blenders",
      "vacuumcleaners"
    ],
    "sharp": [
      "flatscreentelevisions",
      "microwaveovens"
    ],
    "simonschuster": [
      "fictionbooks",
      "non-fictionbooks"
    ],
    "skyworth": [
      "flatscreentelevisions",
      "projectors"
    ],
    "smeg": [
      "coffeemachines",
      "toasters"
    ],
    "sona": [
      "blenders",
      "ricecookers"
    ],
    "sonos": [
      "speakers"
    ],
    "sony": [
      "camerabodies",
      "cellphones"
    ],
    "spotexcelpublishers": [
      "textbooks"
    ],
    "takahi": [
      "ricecookers",
      "slowcookers"
    ],
    "tangs": [
      "fryingpans",
      "saucepots"
    ],
    "taschen": [
      "artbooks"
    ],
    "tcl": [
      "flatscreentelevisions"
    ],
    "tecno": [
      "winecoolers"
    ],
    "tefal": [
      "garmentirons",
      "ricecookers"
    ],
    "tescom": [
      "hairdryers",
      "hairirons"
    ],
    "texasinstruments": [
      "calculators"
    ],
    "tiger": [
      "airpots",
      "kettles"
    ],
    "toshiba": [
      "microwaveovens",
      "ricecookers"
    ],
    "tott": [
      "fryingpans",
      "saucepots"
    ],
    "toyomi": [
      "ricecookers",
      "toasterovens"
    ],
    "tplink": [
      "routers"
    ],
    "tramontina": [
      "fryingpans",
      "saucepots"
    ],
    "trusens": [
      "airtreaters"
    ],
    "u-like": [
      "blenders"
    ],
    "ue": [
      "headphones",
      "speakers"
    ],
    "viewsonic": [
      "monitors",
      "projectors"
    ],
    "vintec": [
      "winecoolers"
    ],
    "vitamix": [
      "blenders"
    ],
    "vitantonio": [
      "blenders",
      "waffleirons"
    ],
    "vivo": [
      "cellphones"
    ],
    "vizio": [
      "flatscreentelevisions",
      "hometheatreavsystems"
    ],
    "vornado": [
      "airtreaters",
      "floorfans"
    ],
    "vssassoon": [
      "haircurlers",
      "hairdryers"
    ],
    "wellra": [
      "juicers"
    ],
    "winix": [
      "airtreaters"
    ],
    "wmf": [
      "blenders",
      "foodprocessors"
    ],
    "worldkitchen": [
      "fryers",
      "ricecookers"
    ],
    "xiaomi": [
      "cellphones",
      "smartwatches"
    ],
    "yamaha": [
      "musickeyboards",
      "soundbars"
    ],
    "zojirushi": [
      "airpots",
      "ricecookers"
    ],
    "zte": [
      "cellphones",
      "routers"
    ],
    "wiley": [
      "textbooks",
      "referencebooks"
    ],
    "shein": [
      "trousers",
      "shirts"
    ],
    "gap": [
      "trousers",
      "shirts"
    ]
  }
];

export const brandData = [
  {
    "hm": {
        "brand": "H&M",
        "offlineRecycle": 581,
        "brandName": "hm"
    },
    "zara": {
        "brand": "Zara",
        "offlineRecycle": 578,
        "brandName": "zara"
    },
    "forever21": {
        "brand": "Forever21",
        "offlineRecycle": 577,
        "brandName": "forever21"
    },
    "uniqlo": {
        "brand": "Uniqlo",
        "offlineRecycle": 576,
        "brandName": "uniqlo"
    },
    "cottonon": {
        "brand": "Cotton On",
        "offlineRecycle": 481,
        "brandName": "cottonon"
    },
    "6ixty8ight": {
        "brand": "6ixty8ight",
        "offlineRecycle": 434,
        "brandName": "6ixty8ight"
    },
    "oppo": {
        "brand": "Oppo",
        "offlineRecycle": 394,
        "brandName": "oppo",
        "tiles": [
            "cellphones",
            "headphones"
        ]
    },
    "penguinrandomhouse": {
        "brand": "Penguin Random House",
        "offlineRecycle": 373,
        "brandName": "penguinrandomhouse",
        "tiles": [
            "classicnovels",
            "fictionbooks"
        ]
    },
    "pearson": {
        "brand": "Pearson",
        "offlineRecycle": 351,
        "brandName": "pearson",
        "tiles": [
            "referencebooks",
            "textbooks"
        ]
    },
    "samsung": {
        "brand": "Samsung",
        "offlineRecycle": 335,
        "brandName": "samsung",
        "tiles": [
            "cellphones",
            "smartwatches"
        ]
    },
    "muji": {
        "brand": "Muji",
        "offlineRecycle": 293,
        "brandName": "muji"
    },
    "tedbaker": {
        "brand": "Ted Baker",
        "offlineRecycle": 292,
        "brandName": "tedbaker"
    },
    "texasinstruments": {
        "brand": "Texas Instruments",
        "offlineRecycle": 286,
        "brandName": "texasinstruments",
        "tiles": [
            "calculators"
        ]
    },
    "panasonic": {
        "brand": "Panasonic",
        "offlineRecycle": 285,
        "brandName": "panasonic",
        "tiles": [
            "camerabodies",
            "ricecookers"
        ]
    },
    "calvinklein": {
        "brand": "Calvin Klein",
        "offlineRecycle": 281,
        "brandName": "calvinklein"
    },
    "g2000": {
        "brand": "G2000",
        "offlineRecycle": 276,
        "brandName": "g2000"
    },
    "boss": {
        "brand": "Boss",
        "offlineRecycle": 275,
        "brandName": "boss"
    },
    "microsoft": {
        "brand": "Microsoft",
        "offlineRecycle": 275,
        "brandName": "microsoft",
        "tiles": [
            "gamingconsoles",
            "laptops"
        ]
    },
    "bimbaylola": {
        "brand": "Bimba y Lola",
        "offlineRecycle": 272,
        "brandName": "bimbaylola"
    },
    "philips": {
        "brand": "Philips",
        "offlineRecycle": 271,
        "brandName": "philips",
        "tiles": [
            "ricecookers",
            "vacuumcleaners"
        ]
    },
    "adidas": {
        "brand": "Adidas",
        "offlineRecycle": 268,
        "brandName": "adidas"
    },
    "cisco": {
        "brand": "Cisco",
        "offlineRecycle": 263,
        "brandName": "cisco",
        "tiles": [
            "routers"
        ]
    },
    "asos": {
        "brand": "ASOS",
        "offlineRecycle": 257,
        "brandName": "asos"
    },
    "tefal": {
        "brand": "Tefal",
        "offlineRecycle": 255,
        "brandName": "tefal",
        "tiles": [
            "garmentirons",
            "ricecookers"
        ]
    },
    "bysi": {
        "brand": "bYSI",
        "offlineRecycle": 249,
        "brandName": "bysi"
    },
    "apple": {
        "brand": "Apple",
        "offlineRecycle": 248,
        "brandName": "apple",
        "tiles": [
            "cellphones",
            "laptops"
        ]
    },
    "guess": {
        "brand": "Guess",
        "offlineRecycle": 247,
        "brandName": "guess"
    },
    "casio": {
        "brand": "Casio",
        "offlineRecycle": 245,
        "brandName": "casio",
        "tiles": [
            "calculators",
            "projectors"
        ]
    },
    "jabra": {
        "brand": "Jabra",
        "offlineRecycle": 240,
        "brandName": "jabra",
        "tiles": [
            "conferencespeakers",
            "headphones"
        ]
    },
    "levis": {
        "brand": "Levis",
        "offlineRecycle": 239,
        "brandName": "levis"
    },
    "dell": {
        "brand": "Dell",
        "offlineRecycle": 232,
        "brandName": "dell",
        "tiles": [
            "desktops",
            "laptops"
        ]
    },
    "iroo": {
        "brand": "iROO",
        "offlineRecycle": 231,
        "brandName": "iroo"
    },
    "logitech": {
        "brand": "Logitech",
        "offlineRecycle": 200,
        "brandName": "logitech",
        "tiles": [
            "arvrheadsets",
            "webcameras"
        ]
    },
    "bose": {
        "brand": "Bose",
        "offlineRecycle": 200,
        "brandName": "bose",
        "tiles": [
            "headphones",
            "speakers"
        ]
    },
    "tcl": {
        "brand": "TCL",
        "offlineRecycle": 196,
        "brandName": "tcl",
        "tiles": [
            "flatscreentelevisions"
        ]
    },
    "lovebonito": {
        "brand": "Love Bonito",
        "offlineRecycle": 196,
        "brandName": "lovebonito"
    },
    "underarmour": {
        "brand": "Under Armour",
        "offlineRecycle": 196,
        "brandName": "underarmour"
    },
    "haier": {
        "brand": "Haier",
        "offlineRecycle": 196,
        "brandName": "haier",
        "tiles": [
            "airtreaters",
            "vacuumcleaners"
        ]
    },
    "mistral": {
        "brand": "Mistral",
        "offlineRecycle": 193,
        "brandName": "mistral",
        "tiles": [
            "ricecookers"
        ]
    },
    "netgear": {
        "brand": "Netgear",
        "offlineRecycle": 193,
        "brandName": "netgear"
    },
    "nintendo": {
        "brand": "Nintendo",
        "offlineRecycle": 193,
        "brandName": "nintendo",
        "tiles": [
            "gamingconsoles"
        ]
    },
    "kitchenaid": {
        "brand": "KitchenAid",
        "offlineRecycle": 192,
        "brandName": "kitchenaid",
        "tiles": [
            "foodprocessors",
            "standmixers"
        ]
    },
    "ue": {
        "brand": "UE",
        "offlineRecycle": 191,
        "brandName": "ue",
        "tiles": [
            "headphones",
            "speakers"
        ]
    },
    "lenovo": {
        "brand": "Lenovo",
        "offlineRecycle": 190,
        "brandName": "lenovo",
        "tiles": [
            "laptops",
            "tablets"
        ]
    },
    "jbl": {
        "brand": "JBL",
        "offlineRecycle": 189,
        "brandName": "jbl",
        "tiles": [
            "headphones",
            "speakers"
        ]
    },
    "amazon": {
        "brand": "Amazon",
        "offlineRecycle": 185,
        "brandName": "amazon",
        "tiles": [
            "ereaders",
            "smarthomedevices"
        ]
    },
    "brother": {
        "brand": "Brother",
        "offlineRecycle": 185,
        "brandName": "brother",
        "tiles": [
            "printers",
            "scanners"
        ]
    },
    "kappa": {
        "brand": "Kappa",
        "offlineRecycle": 182,
        "brandName": "kappa"
    },
    "cos": {
        "brand": "COS",
        "offlineRecycle": 181,
        "brandName": "cos"
    },
    "bershka": {
        "brand": "Bershka",
        "offlineRecycle": 181,
        "brandName": "bershka"
    },
    "nikon": {
        "brand": "Nikon",
        "offlineRecycle": 181,
        "brandName": "nikon",
        "tiles": [
            "camerabodies",
            "cameralenses"
        ]
    },
    "pioneer": {
        "brand": "Pioneer",
        "offlineRecycle": 181,
        "brandName": "pioneer",
        "tiles": [
            "headphones",
            "hometheatreavsystems"
        ]
    },
    "brandymelville": {
        "brand": "Brandy Melville",
        "offlineRecycle": 180,
        "brandName": "brandymelville"
    },
    "mango": {
        "brand": "Mango",
        "offlineRecycle": 180,
        "brandName": "mango"
    },
    "educationalpublishinghouse": {
        "brand": "Educational Publishing House",
        "offlineRecycle": 179,
        "brandName": "educationalpublishinghouse",
        "tiles": [
            "textbooks"
        ]
    },
    "sharp": {
        "brand": "Sharp",
        "offlineRecycle": 179,
        "brandName": "sharp",
        "tiles": [
            "flatscreentelevisions",
            "microwaveovens"
        ]
    },
    "delonghi": {
        "brand": "DeLonghi",
        "offlineRecycle": 179,
        "brandName": "delonghi",
        "tiles": [
            "coffeegrinders",
            "coffeemachines"
        ]
    },
    "dyson": {
        "brand": "Dyson",
        "offlineRecycle": 179,
        "brandName": "dyson",
        "tiles": [
            "floorfans",
            "vacuumcleaners"
        ]
    },
    "mayer": {
        "brand": "Mayer",
        "offlineRecycle": 178,
        "brandName": "mayer",
        "tiles": [
            "ricecookers",
            "winecoolers"
        ]
    },
    "aeropostale": {
        "brand": "Aeropostale",
        "offlineRecycle": 178,
        "brandName": "aeropostale"
    },
    "google": {
        "brand": "Google",
        "offlineRecycle": 177,
        "brandName": "google",
        "tiles": [
            "cellphones",
            "smarthomedevices"
        ]
    },
    "ricoh": {
        "brand": "Ricoh",
        "offlineRecycle": 176,
        "brandName": "ricoh",
        "tiles": [
            "camerabodies",
            "projectors"
        ]
    },
    "karcher": {
        "brand": "Karcher",
        "offlineRecycle": 175,
        "brandName": "karcher",
        "tiles": [
            "vacuumcleaners"
        ]
    },
    "marshallcavendish": {
        "brand": "Marshall Cavendish",
        "offlineRecycle": 172,
        "brandName": "marshallcavendish",
        "tiles": [
            "childrensbooks",
            "textbooks"
        ]
    },
    "desigual": {
        "brand": "Desigual",
        "offlineRecycle": 172,
        "brandName": "desigual"
    },
    "toshiba": {
        "brand": "Toshiba",
        "offlineRecycle": 170,
        "brandName": "toshiba",
        "tiles": [
            "microwaveovens",
            "ricecookers"
        ]
    },
    "coway": {
        "brand": "Coway",
        "offlineRecycle": 169,
        "brandName": "coway",
        "tiles": [
            "airtreaters"
        ]
    },
    "hachette": {
        "brand": "Hachette",
        "offlineRecycle": 168,
        "brandName": "hachette",
        "tiles": [
            "fictionbooks",
            "non-fictionbooks"
        ]
    },
    "xiaomi": {
        "brand": "Xiaomi",
        "offlineRecycle": 168,
        "brandName": "xiaomi",
        "tiles": [
            "cellphones",
            "smartwatches"
        ]
    },
    "lacoste": {
        "brand": "Lacoste",
        "offlineRecycle": 167,
        "brandName": "lacoste"
    },
    "spotexcelpublishers": {
        "brand": "Spot Excel Publishers",
        "offlineRecycle": 166,
        "brandName": "spotexcelpublishers",
        "tiles": [
            "textbooks"
        ]
    },
    "asus": {
        "brand": "Asus",
        "offlineRecycle": 165,
        "brandName": "asus",
        "tiles": [
            "gaminglaptops",
            "laptops"
        ]
    },
    "nike": {
        "brand": "Nike",
        "offlineRecycle": 164,
        "brandName": "nike"
    },
    "macmillan": {
        "brand": "Macmillan",
        "offlineRecycle": 164,
        "brandName": "macmillan",
        "tiles": [
            "classicnovels",
            "referencebooks"
        ]
    },
    "vizio": {
        "brand": "Vizio",
        "offlineRecycle": 163,
        "brandName": "vizio",
        "tiles": [
            "flatscreentelevisions",
            "hometheatreavsystems"
        ]
    },
    "benjaminbarker": {
        "brand": "Benjamin Barker",
        "offlineRecycle": 163,
        "brandName": "benjaminbarker"
    },
    "huawei": {
        "brand": "Huawei",
        "offlineRecycle": 162,
        "brandName": "huawei",
        "tiles": [
            "cellphones",
            "smartwatches"
        ]
    },
    "cornell": {
        "brand": "Cornell",
        "offlineRecycle": 160,
        "brandName": "cornell",
        "tiles": [
            "kettles",
            "slowcookers"
        ]
    },
    "zojirushi": {
        "brand": "Zojirushi",
        "offlineRecycle": 159,
        "brandName": "zojirushi",
        "tiles": [
            "airpots",
            "ricecookers"
        ]
    },
    "realme": {
        "brand": "Realme",
        "offlineRecycle": 158,
        "brandName": "realme",
        "tiles": [
            "cellphones",
            "smartwatches"
        ]
    },
    "fila": {
        "brand": "Fila",
        "offlineRecycle": 158,
        "brandName": "fila"
    },
    "tiger": {
        "brand": "Tiger",
        "offlineRecycle": 157,
        "brandName": "tiger",
        "tiles": [
            "airpots",
            "kettles"
        ]
    },
    "canon": {
        "brand": "Canon",
        "offlineRecycle": 154,
        "brandName": "canon",
        "tiles": [
            "camerabodies",
            "printers"
        ]
    },
    "acer": {
        "brand": "Acer",
        "offlineRecycle": 152,
        "brandName": "acer",
        "tiles": [
            "desktops",
            "laptops"
        ]
    },
    "lg": {
        "brand": "LG",
        "offlineRecycle": 150,
        "brandName": "lg",
        "tiles": [
            "cellphones",
            "laptops"
        ]
    },
    "krups": {
        "brand": "Krups",
        "offlineRecycle": 149,
        "brandName": "krups",
        "tiles": [
            "coffeemachines",
            "toasters"
        ]
    },
    "kdk": {
        "brand": "KDK",
        "offlineRecycle": 148,
        "brandName": "kdk",
        "tiles": [
            "floorfans"
        ]
    },
    "epson": {
        "brand": "Epson",
        "offlineRecycle": 146,
        "brandName": "epson",
        "tiles": [
            "printers",
            "projectors"
        ]
    },
    "linksys": {
        "brand": "Linksys",
        "offlineRecycle": 146,
        "brandName": "linksys",
        "tiles": [
            "routers"
        ]
    },
    "sony": {
        "brand": "Sony",
        "offlineRecycle": 146,
        "brandName": "sony",
        "tiles": [
            "camerabodies",
            "cellphones"
        ]
    },
    "lululemon": {
        "brand": "Lululemon",
        "offlineRecycle": 146,
        "brandName": "lululemon"
    },
    "nokia": {
        "brand": "Nokia",
        "offlineRecycle": 144,
        "brandName": "nokia",
        "tiles": [
            "cellphones",
            "routers"
        ]
    },
    "hp": {
        "brand": "HP",
        "offlineRecycle": 143,
        "brandName": "hp",
        "tiles": [
            "laptops",
            "printers"
        ]
    },
    "hisense": {
        "brand": "Hisense",
        "offlineRecycle": 143,
        "brandName": "hisense",
        "tiles": [
            "cellphones",
            "flatscreentelevisions"
        ]
    },
    "abercrombiefitch": {
        "brand": "Abercrombie & Fitch",
        "offlineRecycle": 143,
        "brandName": "abercrombiefitch"
    },
    "bosch": {
        "brand": "Bosch",
        "offlineRecycle": 139,
        "brandName": "bosch",
        "tiles": [
            "juicers",
            "kettles"
        ]
    },
    "belkin": {
        "brand": "Belkin",
        "offlineRecycle": 138,
        "brandName": "belkin"
    },
    "fitbit": {
        "brand": "Fitbit",
        "offlineRecycle": 138,
        "brandName": "fitbit",
        "tiles": [
            "smartwatches"
        ]
    },
    "ralphlauren": {
        "brand": "Ralph Lauren",
        "offlineRecycle": 136,
        "brandName": "ralphlauren"
    },
    "editorsmarket": {
        "brand": "Editor’s Market",
        "offlineRecycle": 134,
        "brandName": "editorsmarket"
    },
    "motorola": {
        "brand": "Motorola",
        "offlineRecycle": 130,
        "brandName": "motorola",
        "tiles": [
            "cellphones",
            "telephones"
        ]
    },
    "nespresso": {
        "brand": "Nespresso",
        "offlineRecycle": 130,
        "brandName": "nespresso"
    },
    "devialet": {
        "brand": "Devialet",
        "offlineRecycle": 99,
        "brandName": "devialet",
        "tiles": [
            "headphones",
            "speakers"
        ]
    },
    "nakamichi": {
        "brand": "Nakamichi",
        "offlineRecycle": 97,
        "brandName": "nakamichi",
        "tiles": [
            "digitalclocks",
            "speakers"
        ]
    },
    "bitmain": {
        "brand": "Bitmain",
        "offlineRecycle": 96,
        "brandName": "bitmain",
        "tiles": [
            "asicminers"
        ]
    },
    "superdry": {
        "brand": "Superdry",
        "offlineRecycle": 96,
        "brandName": "superdry"
    },
    "olympus": {
        "brand": "Olympus",
        "offlineRecycle": 96,
        "brandName": "olympus",
        "tiles": [
            "camerabodies",
            "cameralenses"
        ]
    },
    "creative": {
        "brand": "Creative",
        "offlineRecycle": 94,
        "brandName": "creative",
        "tiles": [
            "headphones",
            "speakers"
        ]
    },
    "soundteoh": {
        "brand": "Soundteoh",
        "offlineRecycle": 93,
        "brandName": "soundteoh"
    },
    "honeywell": {
        "brand": "Honeywell",
        "offlineRecycle": 93,
        "brandName": "honeywell",
        "tiles": [
            "airtreaters",
            "floorfans"
        ]
    },
    "tplink": {
        "brand": "TPLink",
        "offlineRecycle": 93,
        "brandName": "tplink",
        "tiles": [
            "routers"
        ]
    },
    "qlothe": {
        "brand": "Qlothe",
        "offlineRecycle": 92,
        "brandName": "qlothe"
    },
    "reformation": {
        "brand": "Reformation",
        "offlineRecycle": 92,
        "brandName": "reformation"
    },
    "electrolux": {
        "brand": "Electrolux",
        "offlineRecycle": 92,
        "brandName": "electrolux",
        "tiles": [
            "vacuumcleaners",
            "airtreaters"
        ]
    },
    "yacht21": {
        "brand": "Yacht 21",
        "offlineRecycle": 90,
        "brandName": "yacht21"
    },
    "sonos": {
        "brand": "Sonos",
        "offlineRecycle": 89,
        "brandName": "sonos",
        "tiles": [
            "speakers"
        ]
    },
    "yamaha": {
        "brand": "Yamaha",
        "offlineRecycle": 88,
        "brandName": "yamaha",
        "tiles": [
            "musickeyboards",
            "soundbars"
        ]
    },
    "tmlewin": {
        "brand": "TM Lewin",
        "offlineRecycle": 88,
        "brandName": "tmlewin"
    },
    "pullbear": {
        "brand": "Pull & Bear",
        "offlineRecycle": 88,
        "brandName": "pullbear"
    },
    "bernina": {
        "brand": "Bernina",
        "offlineRecycle": 87,
        "brandName": "bernina",
        "tiles": [
            "sewingmachines"
        ]
    },
    "simonschuster": {
        "brand": "Simon Schuster",
        "offlineRecycle": 83,
        "brandName": "simonschuster",
        "tiles": [
            "fictionbooks",
            "non-fictionbooks"
        ]
    },
    "zte": {
        "brand": "ZTE",
        "offlineRecycle": 82,
        "brandName": "zte",
        "tiles": [
            "cellphones",
            "routers"
        ]
    },
    "blendtec": {
        "brand": "Blendtec",
        "offlineRecycle": 82,
        "brandName": "blendtec"
    },
    "cuisinart": {
        "brand": "Cuisinart",
        "offlineRecycle": 81,
        "brandName": "cuisinart",
        "tiles": [
            "blenders",
            "icecreammachines"
        ]
    },
    "klipsch": {
        "brand": "Klipsch",
        "offlineRecycle": 80,
        "brandName": "klipsch",
        "tiles": [
            "headphones",
            "speakers"
        ]
    },
    "benq": {
        "brand": "BenQ",
        "offlineRecycle": 78,
        "brandName": "benq",
        "tiles": [
            "monitors",
            "projectors"
        ]
    },
    "europace": {
        "brand": "Europace",
        "offlineRecycle": 78,
        "brandName": "europace",
        "tiles": [
            "airtreaters",
            "winecoolers"
        ]
    },
    "vintec": {
        "brand": "Vintec",
        "offlineRecycle": 76,
        "brandName": "vintec",
        "tiles": [
            "winecoolers"
        ]
    },
    "htcvive": {
        "brand": "HTC VIVE",
        "offlineRecycle": 74,
        "brandName": "htcvive",
        "tiles": [
            "arvrheadsets"
        ]
    },
    "vivo": {
        "brand": "Vivo",
        "offlineRecycle": 73,
        "brandName": "vivo",
        "tiles": [
            "cellphones"
        ]
    },
    "theshirtbar": {
        "brand": "The Shirt Bar",
        "offlineRecycle": 73,
        "brandName": "theshirtbar"
    },
    "skyworth": {
        "brand": "Skyworth",
        "offlineRecycle": 72,
        "brandName": "skyworth",
        "tiles": [
            "flatscreentelevisions",
            "projectors"
        ]
    },
    "midea": {
        "brand": "Midea",
        "offlineRecycle": 72,
        "brandName": "midea",
        "tiles": [
            "toasters",
            "vacuumcleaners"
        ]
    },
    "crockpot": {
        "brand": "Crock Pot",
        "offlineRecycle": 71,
        "brandName": "crockpot",
        "tiles": [
            "slowcookers"
        ]
    },
    "brand": {
        "brand": "Brand",
        "offlineRecycle": 71,
        "brandName": "brand"
    },
    "everlane": {
        "brand": "Everlane",
        "offlineRecycle": 70,
        "brandName": "everlane"
    },
    "brandt": {
        "brand": "Brandt",
        "offlineRecycle": 69,
        "brandName": "brandt",
        "tiles": [
            "winecoolers"
        ]
    },
    "ecovacs": {
        "brand": "Ecovacs",
        "offlineRecycle": 69,
        "brandName": "ecovacs",
        "tiles": [
            "vacuumcleaners",
            "windowcleaners"
        ]
    },
    "hoddereducation": {
        "brand": "Hodder Education",
        "offlineRecycle": 68,
        "brandName": "hoddereducation",
        "tiles": [
            "textbooks"
        ]
    },
    "hitachi": {
        "brand": "Hitachi",
        "offlineRecycle": 68,
        "brandName": "hitachi",
        "tiles": [
            "ricecookers",
            "vacuumcleaners"
        ]
    },
    "braun": {
        "brand": "Braun",
        "offlineRecycle": 67,
        "brandName": "braun",
        "tiles": [
            "haircurlers",
            "garmentirons"
        ]
    },
    "taschen": {
        "brand": "Taschen",
        "offlineRecycle": 66,
        "brandName": "taschen",
        "tiles": [
            "artbooks"
        ]
    },
    "irobot": {
        "brand": "iRobot",
        "offlineRecycle": 66,
        "brandName": "irobot",
        "tiles": [
            "vacuumcleaners"
        ]
    },
    "fender": {
        "brand": "Fender",
        "offlineRecycle": 65,
        "brandName": "fender",
        "tiles": [
            "amplifiers",
            "speakers"
        ]
    },
    "nec": {
        "brand": "NEC",
        "offlineRecycle": 65,
        "brandName": "nec",
        "tiles": [
            "laptops",
            "projectors"
        ]
    },
    "tommyhilfiger": {
        "brand": "Tommy Hilfiger",
        "offlineRecycle": 65,
        "brandName": "tommyhilfiger"
    },
    "dlink": {
        "brand": "Dlink",
        "offlineRecycle": 65,
        "brandName": "dlink",
        "tiles": [
            "routers",
            "smarthomedevices"
        ]
    },
    "massimodutti": {
        "brand": "Massimo Dutti",
        "offlineRecycle": 64,
        "brandName": "massimodutti"
    },
    "smeg": {
        "brand": "Smeg",
        "offlineRecycle": 62,
        "brandName": "smeg",
        "tiles": [
            "coffeemachines",
            "toasters"
        ]
    },
    "illy": {
        "brand": "Illy",
        "offlineRecycle": 61,
        "brandName": "illy",
        "tiles": [
            "coffeemachines"
        ]
    },
    "viewsonic": {
        "brand": "Viewsonic",
        "offlineRecycle": 61,
        "brandName": "viewsonic",
        "tiles": [
            "monitors",
            "projectors"
        ]
    },
    "sennheiser": {
        "brand": "Sennheiser",
        "offlineRecycle": 59,
        "brandName": "sennheiser",
        "tiles": [
            "headphones",
            "microphones"
        ]
    },
    "msi": {
        "brand": "MSI",
        "offlineRecycle": 58,
        "brandName": "msi",
        "tiles": [
            "gaminglaptops"
        ]
    },
    "fujifilm": {
        "brand": "Fujifilm",
        "offlineRecycle": 58,
        "brandName": "fujifilm",
        "tiles": [
            "camerabodies",
            "cameralenses"
        ]
    },
    "roku": {
        "brand": "Roku",
        "offlineRecycle": 57,
        "brandName": "roku",
        "tiles": [
            "digitalmediaplayers"
        ]
    },
    "cado": {
        "brand": "Cado",
        "offlineRecycle": 57,
        "brandName": "cado"
    },
    "kadeka": {
        "brand": "Kadeka",
        "offlineRecycle": 56,
        "brandName": "kadeka",
        "tiles": [
            "winecoolers"
        ]
    },
    "jura": {
        "brand": "Jura",
        "offlineRecycle": 56,
        "brandName": "jura",
        "tiles": [
            "coffeemachines"
        ]
    },
    "novita": {
        "brand": "Novita",
        "offlineRecycle": 55,
        "brandName": "novita",
        "tiles": [
            "airtreaters",
            "waterpurifiers"
        ]
    },
    "gopro": {
        "brand": "GoPro",
        "offlineRecycle": 54,
        "brandName": "gopro",
        "tiles": [
            "camerabodies"
        ]
    },
    "hamiltonbeach": {
        "brand": "Hamilton Beach",
        "offlineRecycle": 54,
        "brandName": "hamiltonbeach",
        "tiles": [
            "blenders",
            "garmentirons"
        ]
    },
    "garmin": {
        "brand": "Garmin",
        "offlineRecycle": 54,
        "brandName": "garmin",
        "tiles": [
            "smartwatches"
        ]
    },
    "marshall": {
        "brand": "Marshall",
        "offlineRecycle": 53,
        "brandName": "marshall",
        "tiles": [
            "amplifiers",
            "speakers"
        ]
    },
    "oneplus": {
        "brand": "OnePlus",
        "offlineRecycle": 53,
        "brandName": "oneplus",
        "tiles": [
            "cellphones",
            "headphones"
        ]
    },
    "gigaset": {
        "brand": "Gigaset",
        "offlineRecycle": 53,
        "brandName": "gigaset",
        "tiles": [
            "telephones"
        ]
    },
    "vitamix": {
        "brand": "Vitamix",
        "offlineRecycle": 52,
        "brandName": "vitamix",
        "tiles": [
            "blenders"
        ]
    },
    "aritzia": {
        "brand": "Aritzia",
        "offlineRecycle": 50,
        "brandName": "aritzia"
    },
    "miele": {
        "brand": "Miele",
        "offlineRecycle": 49,
        "brandName": "miele",
        "tiles": [
            "coffeemachines",
            "vacuumcleaners"
        ]
    },
    "breville": {
        "brand": "Breville",
        "offlineRecycle": 48,
        "brandName": "breville",
        "tiles": [
            "coffeemachines",
            "juicers"
        ]
    },
    "chateau": {
        "brand": "Chateau",
        "offlineRecycle": 48,
        "brandName": "chateau",
        "tiles": [
            "winecoolers"
        ]
    },
    "phaidon": {
        "brand": "Phaidon",
        "offlineRecycle": 47,
        "brandName": "phaidon",
        "tiles": [
            "artbooks",
            "childrensbooks"
        ]
    },
    "clickgrow": {
        "brand": "Click & Grow",
        "offlineRecycle": 45,
        "brandName": "clickgrow",
        "tiles": [
          "indoorgardeningkits"
        ]
    },
    "blackberry": {
        "brand": "Blackberry",
        "offlineRecycle": 44,
        "brandName": "blackberry",
        "tiles": [
            "cellphones"
        ]
    },
    "leica": {
        "brand": "Leica",
        "offlineRecycle": 44,
        "brandName": "leica",
        "tiles": [
            "camerabodies",
            "cameralenses"
        ]
    },
    "harmankardon": {
        "brand": "Harman Kardon",
        "offlineRecycle": 44,
        "brandName": "harmankardon",
        "tiles": [
            "smarthomedevices",
            "speakers"
        ]
    },
    "tecno": {
        "brand": "Tecno",
        "offlineRecycle": 44,
        "brandName": "tecno",
        "tiles": [
            "winecoolers"
        ]
    },
    "dji": {
        "brand": "DJI",
        "offlineRecycle": 43,
        "brandName": "dji",
        "tiles": [
            "drones",
            "stabilisers"
        ]
    },
    "sharkninja": {
        "brand": "Shark Ninja",
        "offlineRecycle": 42,
        "brandName": "sharkninja"
    },
    "razer": {
        "brand": "Razer",
        "offlineRecycle": 42,
        "brandName": "razer",
        "tiles": [
            "gaminglaptops",
            "gamingperipherals"
        ]
    },
    "sanssans": {
        "brand": "Sans & Sans",
        "offlineRecycle": 39,
        "brandName": "sanssans"
    },
    "oster": {
        "brand": "Oster",
        "offlineRecycle": 38,
        "brandName": "oster",
        "tiles": [
            "blenders",
            "toasters"
        ]
    },
    "canaan": {
        "brand": "Canaan",
        "offlineRecycle": 37,
        "brandName": "canaan",
        "tiles": [
            "asicminers"
        ]
    },
    "blueair": {
        "brand": "Blueair",
        "offlineRecycle": 35,
        "brandName": "blueair",
        "tiles": [
            "airtreaters"
        ]
    },
    "blackdecker": {
        "brand": "Black Decker",
        "offlineRecycle": 34,
        "brandName": "blackdecker",
        "tiles": [
            "handpowertools",
            "vacuumcleaners"
        ]
    },
    "ingoodcompany": {
        "brand": "In Good Company",
        "offlineRecycle": 32,
        "brandName": "ingoodcompany"
    },
    "klarra": {
        "brand": "Klarra",
        "offlineRecycle": 32,
        "brandName": "klarra"
    },
    "sona": {
        "brand": "Sona",
        "offlineRecycle": 31,
        "brandName": "sona",
        "tiles": [
            "blenders",
            "ricecookers"
        ]
    },
    "parrot": {
        "brand": "Parrot",
        "offlineRecycle": 31,
        "brandName": "parrot",
        "tiles": [
            "drones"
        ]
    },
    "oculus": {
        "brand": "Oculus",
        "offlineRecycle": 30,
        "brandName": "oculus",
        "tiles": [
            "arvrheadsets"
        ]
    },
    "bangolufsen": {
        "brand": "Bang & Olufsen",
        "offlineRecycle": 30,
        "brandName": "bangolufsen",
        "tiles": [
            "headphones",
            "speakers"
        ]
    },
    "jvc": {
        "brand": "JVC",
        "offlineRecycle": 30,
        "brandName": "jvc",
        "tiles": [
            "carvideorecorders",
            "flatscreentelevisions"
        ]
    },
    "gamevice": {
        "brand": "Gamevice",
        "offlineRecycle": 30,
        "brandName": "gamevice",
        "tiles": [
            "gamingperipherals"
        ]
    },
    "harpercollins": {
        "brand": "Harper Collins",
        "offlineRecycle": 30,
        "brandName": "harpercollins",
        "tiles": [
            "fictionbooks",
            "non-fictionbooks"
        ]
    },
    "wiley": {
      "brand": "Wiley",
      "offlineRecycle": 0,
      "brandName": "wiley",
      "tiles": [
        "textbooks",
        "referencebooks"
      ]
    },
    "shein": {
      "brand": "SHEIN",
      "offlineRecycle": 0,
      "brandName": "shein",
      "tiles": [
        "trousers",
        "shirts"
      ]
    },
    "gap": {
      "brand": "GAP",
      "offlineRecycle": 0,
      "brandName": "gap",
      "tiles": [
        "trousers",
        "shirts"
      ]
    }
  }
]

export default {brandTiles, brandData};

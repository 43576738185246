// Import dependencies
import React from "react"
import { navigate } from "gatsby"

// Import components
import InputField from "../form/input/inputField"
import { TitleText, ParagraphText } from "../../styled-components/UILibrary"
import GeneralButton from "../form/buttons/GeneralButton"
import CheckIcon from "../../assets/icons/check.svg";
import Help from "../../assets/icons/help.svg";
import CheckHelp from "../../assets/icons/check_circle.svg";

/*
    Forgot Password Component
*/
export default class brandPopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      error: null,
    }
  }

  renderForm = () => {
    return(<>

      <InputField placeholder="Street / Blk. / Unit No." noForm/>

      <InputField placeholder="Postal Code" noForm/>

      <InputField placeholder="Email" noForm/>

      <InputField placeholder="Contact Number" noForm/>

      {this.state.error ? (
        <p
            style={{
              fontFamily: "Inter, sans-serif !important",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "12px",
              lineHeight: "16px",
              letterSpacing: "0.4px",
              color: "#FF0C3E",
              opacity: 0.6,
              textAlign: "left",
              marginLeft: "20px",
              marginTop: "-20px"
            }}
        >
          {this.state.error}
        </p>
      ) : null}

      <div className="edit-profil__button" onClick={this.props.showSuccess}>
        <GeneralButton
          title="Save"
          type="primary"
          fit

          margin={{ marginTop: 15 }}
        />
      </div>
      </>)
  }

  render() {

      return (
        <div className="popup_container"  style={this.props.popupOpen ? {} : {display: "none"}}>
        <div className="popup small-popup">
        <div className="brand-help__content">
          <TitleText className="title-brand-help" subTitle center margin="margin-bottom: 40px" style={this.props.success ? {display: "none"} : {}}>
            Badges
          </TitleText>
          <div className="category-drawer__container-close-button" onClick={this.props.togglePopup}>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.4 0.613295C16.88 0.0932946 16.04 0.0932946 15.52 0.613295L8.99996 7.11996L2.47996 0.599961C1.95996 0.079961 1.11996 0.079961 0.599961 0.599961C0.079961 1.11996 0.079961 1.95996 0.599961 2.47996L7.11996 8.99996L0.599961 15.52C0.079961 16.04 0.079961 16.88 0.599961 17.4C1.11996 17.92 1.95996 17.92 2.47996 17.4L8.99996 10.88L15.52 17.4C16.04 17.92 16.88 17.92 17.4 17.4C17.92 16.88 17.92 16.04 17.4 15.52L10.88 8.99996L17.4 2.47996C17.9066 1.97329 17.9066 1.11996 17.4 0.613295V0.613295Z" fill="#828282"/>
          </svg>
          </div>
          <Help style={{marginBottom: 10}}/>
          <h2>No endorsement</h2>
          <p>The brand hasn’t claimed this page 🙁 This buyback program is independently operated by Reinin</p>
          <CheckHelp style={{marginBottom: 10}}/>
          <h2>Brand partner</h2>
          <p style={{marginBottom: 0}}>This is an official buyback program for this brand! Buyback & recycling are fulfilled by Reinin 💪</p>
        </div>
        </div>
        </div>
      )
  }
}

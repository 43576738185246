// Import dependencies
import React from "react"
import { navigate } from "gatsby"

import {connect} from "react-redux";
import {categoryDrawerOpen} from "../../redux/actions/productAction";

import {PageContainer} from "../../styled-components/UILibrary";
import NoBrands from "../../assets/icons/recycle_generic3.svg";
import GeneralButton from "../form/buttons/GeneralButton";

import "./department-content.css";

/*
    Item for What makes us different component from the index page
*/

class BrdNotFound extends React.Component {

      constructor(props) {
          super(props);
          this.onCategorySelectClick = this.onCategorySelectClick.bind(this);
      }

  onCategorySelectClick() {
      this.props.dispatch(categoryDrawerOpen())
  }
  render() {
  return (  <>
    <div className="department_title">All other brands</div>
    <PageContainer  className="no-padding-mobile">
    <div className="cards-list-container no-padding">
      <div className="cards-list">
        <div className="cards-list-item cards-list-item-large" onClick={this.onCategorySelectClick} style={{cursor: "pointer"}}>
          <NoBrands />
          <div className="card-description card-description-large">
            <h2 className="card-title">Try our search bar</h2>
            <p>Find your item by using a department filter in our search bar settings</p>
            <div className="card-btn_container">
              <GeneralButton
              title="See filters"
                  type="primary"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    </PageContainer>
  </>);
  }
}

export default connect()(BrdNotFound);
